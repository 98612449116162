import React, { useState } from "react";
import { DGSButton } from "../../DGSButton";
import { telegram } from "../../../api/telegram";
import { Link } from "react-router-dom";

export const FormQuestion = ({ onClose }) => {
  const formControlClasses =
    "w-full px-6 py-2 text-gray-800 rounded-sm border border-solid border-neutral-300 focus:border-gray-500 focus:outline-none bg-white";
  const controlErrorClasses = "absolute -bottom-4 left-6 text-xs text-red-600";
  const [name, setName] = useState("");
  const [nameDirty, setNameDirty] = useState(false);
  const [nameError, setNameError] = useState(true);
  const [phone, setPhone] = useState("");
  const [phoneDirty, setPhoneDirty] = useState(false);
  const [phoneError, setPhoneError] = useState(true);
  const [eMail, setEMail] = useState("");
  const [eMailDirty, setEMailDirty] = useState(false);
  const [eMailError, setEMailError] = useState(false);
  const [text, setText] = useState("");
  const [textDirty, setTextDirty] = useState(false);
  const [textError, setTextError] = useState(true);
  const [checked, setChecked] = useState(true);

  const blurHandler = (e) => {
    switch (e.target.name) {
      case "name":
        setNameDirty(true);
        break;
      case "phone":
        setPhoneDirty(true);
        break;
      case "eMail":
        setEMailDirty(true);
        break;
      case "text":
        setTextDirty(true);
        break;
      default:
    }
  };
  const nameHandler = (e) => {
    e.target.value.trim().length > 0 ? setNameError(false) : setNameError(true);
    setName(e.target.value);
  };
  const phoneHandler = (e) => {
    const re = /^[\d\+][\d\(\)\ -]{8,18}\d$/; //eslint-disable-line
    re.test(String(e.target.value).toLocaleLowerCase())
      ? setPhoneError(false)
      : setPhoneError(true);
    setPhone(e.target.value);
  };
  const eMailHandler = (e) => {
    const re = /^[\w]{1}[\w-\.]*@[\w-]+\.[a-z]{2,10}$/i; //eslint-disable-line
    re.test(String(e.target.value).toLocaleLowerCase())
      ? setEMailError(false)
      : e.target.value.trim().length > 0
      ? setEMailError(true)
      : setEMailError(false);
    setEMail(e.target.value);
  };
  const textHandler = (e) => {
    e.target.value.trim().length > 0 ? setTextError(false) : setTextError(true);
    setText(e.target.value);
  };
  const checkHandler = (e) => {
    setChecked(!checked);
  };

  const formClean = () => {
    setName("");
    setNameDirty(false);
    setNameError(true);
    setPhone("");
    setPhoneDirty(false);
    setPhoneError(true);
    setEMail("");
    setEMailDirty(false);
    setEMailError(false);
    setText("");
    setTextDirty(false);
    setTextError(true);
    setChecked(true);
  };

  const buttonClicked = () => {
    if (!nameError && !phoneError && !eMailError && !textError && checked) {
      //Отправка формы
      let message = `<strong>Сообщение с сайта DGS:</strong>\nИмя: ${name}\nТелефон: ${phone}\nE-mail: ${eMail}\nСообщение: ${text}`;
      // console.log(message);
      telegram(message);
      // Yandex metrika
      window.ym(99128412, "reachGoal", "FormContactUs_sent");
      formClean();
      onClose && onClose();
    } else {
      setPhoneDirty(true);
      setNameDirty(true);
      setEMailDirty(true);
      setTextDirty(true);
    }
  };

  return (
    <div className="w-full">
      <form className="flex flex-col gap-7 items-center justify-normal">
        <div className="w-full relative">
          <input
            value={name}
            name="name"
            className={formControlClasses}
            placeholder="Имя *"
            onBlur={(e) => blurHandler(e)}
            onChange={(e) => nameHandler(e)}
          ></input>
          {nameDirty && nameError && (
            <div className={controlErrorClasses}>Просьба заполнить имя</div>
          )}
        </div>
        <div className="w-full relative">
          <input
            value={phone}
            name="phone"
            className={formControlClasses}
            placeholder="Телефон *"
            onBlur={(e) => blurHandler(e)}
            onChange={(e) => phoneHandler(e)}
          ></input>
          {phoneDirty && phoneError && (
            <div className={controlErrorClasses}>Укажите корректный номер</div>
          )}
        </div>

        <div className="w-full relative">
          <input
            value={eMail}
            name="eMail"
            className={formControlClasses}
            placeholder="E-mail"
            onBlur={(e) => blurHandler(e)}
            onChange={(e) => eMailHandler(e)}
          ></input>
          {eMailDirty && eMailError && (
            <div className={controlErrorClasses}>Укажите корректный E-mail</div>
          )}
        </div>

        <div className="w-full relative">
          <textarea
            value={text}
            name="text"
            rows="4"
            className={formControlClasses}
            placeholder="Сообщение *"
            onBlur={(e) => blurHandler(e)}
            onChange={(e) => textHandler(e)}
          ></textarea>
          {textDirty && textError && (
            <div className={controlErrorClasses}>Введите сообщение</div>
          )}
        </div>

        <div className="flex items-start pt-1 relative">
          <input
            type="checkbox"
            id="policy"
            name="policy"
            checked={checked}
            onChange={(e) => checkHandler(e)}
          />
          <p className="pl-1 text-xs text-neutral-500 font-light leading-none">
            Нажимая кнопку «Отправить», я даю свое согласие на обработку моих
            персональных данных, в соответствии с Федеральным законом от
            27.07.2006 года №152-ФЗ «О персональных данных», на условиях и для
            целей, определенных в{" "}
            <Link
              to="/policy"
              onClick={() => onClose && onClose()}
              className="text-sky-800 hover:text-sky-600"
            >
              cогласии на обработку персональных данных
            </Link>
            . *
          </p>
          {!checked && (
            <div className={`${controlErrorClasses} left-[17px]`}>
              Необходимо подтвердить согласие на обработку персональных данных
            </div>
          )}
        </div>

        <div className="justify-self-auto mt-3">
          <div className="w-[230px]" onClick={() => buttonClicked()}>
            <DGSButton filled={true} disabled={false}>
              Отправить сообщение
            </DGSButton>
          </div>
        </div>
      </form>
    </div>
  );
};
