import React from "react";
import { Wrapper } from "./Wrapper";
import { Phone } from "./Phone";
import { Link } from "react-router-dom";
// import { SocialIcons } from "../components/SocialIcons";

export const Footer = () => {
  return (
    <div className="bg-zinc-600 ">
      <Wrapper>
        <div className="flex flex-col">
          <div className="flex flex-row justify-between py-10 border-b border-zinc-400">
            <div className="w-[56px] h-[56px] flex justify-center items-center rounded-tl-[22px] rounded-br-[22px] text-white text-lg border border-white font-extrabold">
              DGS
            </div>
            <div className="flex flex-col items-end text-white font-medium text-lg">
              <div className="hover:text-rose-500 select-text">
                <Phone />
              </div>
              <div className="text-xs text-zinc-400 select-none">
                Пн-Пт 10.00-19.00
              </div>
              <div className="text-sm text-white hover:text-rose-500 select-text">
                <a href="mailto:welcome@dgs.team">welcome@dgs.team</a>
              </div>
            </div>
            {/* <SocialIcons iconsLight={true} /> */}
          </div>
          <div className="flex flex-col gap-8 sm:gap-0 sm:flex-row justify-between py-10 border-b border-zinc-400 text-sm text-zinc-300">
            {/* УСЛУГИ-------------------------------------------------- */}
            <div className="flex flex-col gap-4">
              <Link
                className="uppercase text-sm text-white font-semibold pb-2"
                to="/services"
              >
                Услуги
              </Link>
              <Link to="/services/outsourcing">IT аутсорсинг</Link>
              <Link to="services/new-services">ИТ инфраструктура с нуля</Link>
              <Link to="/services/cloud-migration">Миграция в облако</Link>
              <Link to="/services/services-update">
                Модернизация инфраструктуры
              </Link>
              <Link to="/services/consulting-and-audit">
                IT консалтинг и аудит
              </Link>
            </div>
            {/* IT ПОДДЕРЖКА-------------------------------------------------- */}
            <div className="flex flex-col gap-4">
              <Link
                className="uppercase text-sm text-white font-semibold pb-2"
                to="/services/user-support"
              >
                IT обслуживание
              </Link>
              <Link to="/services/user-support">Поддержка пользователей</Link>
              <Link to="/services/hardware-maintenance">
                Обслуживание IT инфраструктуры
              </Link>
              <Link to="/services/data-backup">Резервное копирование</Link>
              <Link to="/services/monitoring">Монироринг</Link>
              <Link to="/services/knowledge-base">База знаний</Link>
            </div>
            {/* ИНФОРМАЦИЯ-------------------------------------------------- */}
            <div className="hidden md:flex flex-col gap-4">
              <div className="uppercase text-sm text-white font-semibold pb-2">
                ИНФОРМАЦИЯ
              </div>
              <Link to="/about-us">О нас</Link>
              <Link to="/partner-program">Партнерская программа</Link>
              <Link to="/vacancies">Вакансии</Link>
            </div>
          </div>
          <div className="text-zinc-400 text-xs pt-10 pb-5">
            2024 © dgs.team | Комплексные IT-Услуги. Все права защищены.
            <br />
            Сайт не является публичной офертой и носит информационный характер.
          </div>
        </div>
      </Wrapper>
    </div>
  );
};
