import { Link } from "react-router-dom";

import { ReactComponent as LogoIcon } from "../images/logo.svg";
// import { MyButton } from "./MyButton";
import { Wrapper } from "./Wrapper";
import { useState } from "react";
import { Phone } from "./Phone";

export const Header = ({ isNavMobileOpen, setNavMobileOpen }) => {
  //shadowHeader
  const [isShadowHeader, setShadowHeader] = useState(false);
  const changeNavCss = () => {
    window.scrollY > 10 ? setShadowHeader(true) : setShadowHeader(false);
  };
  window.addEventListener("scroll", changeNavCss);
  return (
    <header
      className={`${
        isShadowHeader && "shadow-lg bg-white"
      } lg:shadow-none bg-white fixed w-full top-0 lg:relative z-50`}
    >
      <Wrapper>
        <div className="flex justify-between pt-3 lg:pt-6 pb-3 lg:pb-8 items-center">
          <Link to="/" className="cursor-pointer">
            <LogoIcon className="w-14 h-14" />
          </Link>

          {/* Правый блок */}
          <div className="flex flex-row space-x-20 items-center">
            {/* <div className="hidden lg:flex flex-col jastify-end text-sm">
              <strong>АДРЕС</strong>
              <div className="flex justify-end text-xs text-gray-700">
                Москва, ул.Центральная, д.12А
              </div>
            </div> */}

            <div className="hidden md:flex flex-col items-end">
              <strong className="hover:text-rose-500 select-all font-medium text-base">
                <Phone />
              </strong>

              <div className="text-xs text-gray-700">Пн-Пт 10.00-19.00</div>
              <a
                href="mailto:welcome@dgs.team"
                className="text-sm text-rose-500 hover:underline underline-offset-4 select-text"
              >
                welcome@dgs.team
              </a>
            </div>
            {/* <MyButton filled={true}>Консультация</MyButton> */}
            <div className="lg:hidden">
              {!isNavMobileOpen && (
                <svg
                  onClick={() => {
                    setNavMobileOpen(true);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="size-12 cursor-pointer z-30"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              )}
              {isNavMobileOpen && (
                <svg
                  onClick={() => {
                    setNavMobileOpen(false);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="size-12 cursor-pointer"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </header>
  );
};
