import React from "react";
import { PageHeader } from "../../components/PageHeader";
import { Wrapper } from "../../components/Wrapper";
import { FormContactUs } from "../../components/Forms/FormContactUs";
import octos from "./octos.png";
import { FormRequest } from "../../components/Forms/FormRequest";

export const Bpm = () => {
  window.scroll(0, 0);
  return (
    <div>
      <PageHeader pageName="Система OCTOS" />
      <div className="flex flex-col py-10 gap-10">
        <Wrapper>
          <h3>
            Мы смотрим на бизнес процессы с финансовой, коммерческой,
            операционной и технической точки зрения, анализируем эффективность и
            предлагаем комплексное решение по оптимизации работы и
            взаимодействию всех структур компании.
          </h3>
          <p>
            Собственная гибко настраиваемая IT система OCTOS для автоматизации
            работы бизнеса позволяет нам быстро автоматизировать и внедрять все
            согласованные изменения. В результате наши клиенты получают –
            оптимально отстроенную бизнес среду для эффективной работы компании.
          </p>
        </Wrapper>
        <Wrapper>
          {/* Функции системы */}
          <div className="py-5 grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-10">
            <div className="flex flex-col items-center gap-2 text-center">
              <div className="flex h-32 items-center justify-around">
                <svg
                  width="81"
                  height="74"
                  viewBox="0 0 81 74"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.184 12.422C11.8647 12.8727 11.9713 13.4968 12.422 13.816C12.8727 14.1353 13.4968 14.0287 13.816 13.578L12.184 12.422ZM68 13L63.8821 2.21224L56.5985 11.1724L68 13ZM13 13C13.816 13.578 13.8158 13.5784 13.8156 13.5787C13.8155 13.5787 13.8153 13.579 13.8153 13.5791C13.8152 13.5792 13.8152 13.5792 13.8153 13.579C13.8156 13.5786 13.8164 13.5775 13.8177 13.5757C13.8203 13.5721 13.8249 13.5657 13.8316 13.5565C13.8451 13.5382 13.8667 13.5089 13.8967 13.4695C13.9565 13.3906 14.0493 13.2709 14.1754 13.1164C14.4275 12.8071 14.8123 12.3586 15.3314 11.8169C16.37 10.7331 17.9434 9.2799 20.0655 7.82473C24.3045 4.918 30.7359 2 39.5 2V0C30.2641 0 23.4455 3.082 18.9345 6.17527C16.6816 7.7201 15.005 9.26692 13.8874 10.4331C13.3284 11.0164 12.9084 11.5054 12.6254 11.8524C12.4839 12.0259 12.3765 12.1641 12.3031 12.261C12.2663 12.3094 12.2381 12.3476 12.2182 12.3746C12.2083 12.3882 12.2005 12.3989 12.1948 12.4069C12.1919 12.4108 12.1896 12.4141 12.1878 12.4166C12.1869 12.4179 12.1861 12.4189 12.1855 12.4198C12.1852 12.4203 12.1848 12.4208 12.1846 12.421C12.1843 12.4215 12.184 12.422 13 13ZM39.5 2C48.585 2 55.7117 5.13585 60.479 8.16634L61.5519 6.4785C56.5549 3.30194 49.0601 0 39.5 0V2Z"
                    fill="#F43F5E"
                  />
                  <path
                    d="M68.816 61.578C69.1353 61.1273 69.0287 60.5032 68.578 60.184C68.1273 59.8647 67.5032 59.9713 67.184 60.422L68.816 61.578ZM13 61L17.1179 71.7878L24.4015 62.8276L13 61ZM68 61C67.184 60.422 67.1842 60.4216 67.1844 60.4213C67.1845 60.4213 67.1847 60.421 67.1847 60.4209C67.1848 60.4208 67.1848 60.4208 67.1847 60.421C67.1844 60.4214 67.1836 60.4225 67.1823 60.4243C67.1797 60.4279 67.1751 60.4343 67.1684 60.4435C67.1549 60.4618 67.1333 60.4911 67.1033 60.5305C67.0435 60.6094 66.9507 60.7291 66.8246 60.8836C66.5725 61.1929 66.1877 61.6414 65.6686 62.1831C64.63 63.2669 63.0566 64.7201 60.9345 66.1753C56.6955 69.082 50.2641 72 41.5 72V74C50.7359 74 57.5545 70.918 62.0655 67.8247C64.3184 66.2799 65.995 64.7331 67.1126 63.5669C67.6716 62.9836 68.0916 62.4946 68.3746 62.1476C68.5161 61.9741 68.6235 61.8359 68.6969 61.739C68.7337 61.6906 68.7619 61.6524 68.7818 61.6254C68.7917 61.6118 68.7995 61.6011 68.8052 61.5931C68.8081 61.5892 68.8104 61.5859 68.8122 61.5834C68.8131 61.5821 68.8139 61.5811 68.8145 61.5802C68.8148 61.5797 68.8152 61.5792 68.8154 61.579C68.8157 61.5785 68.816 61.578 68 61ZM41.5 72C32.415 72 25.2883 68.8642 20.521 65.8337L19.4481 67.5215C24.4451 70.6981 31.9399 74 41.5 74V72Z"
                    fill="#F43F5E"
                  />
                  <rect
                    x="25.5"
                    y="17.5"
                    width="38.7857"
                    height="24"
                    rx="2.5"
                    transform="rotate(90 25.5 17.5)"
                    stroke="#F43F5E"
                    strokeWidth="3"
                  />
                  <rect
                    x="80"
                    y="17"
                    width="39.7857"
                    height="25"
                    rx="3"
                    transform="rotate(90 80 17)"
                    fill="#FFE4E6"
                    stroke="#F43F5E"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              <p className="pt-5">
                <strong>Электронный документооборот</strong>
              </p>
              <p>
                Создание, учет и подписание документов. Удобство и прозрачность.
              </p>
            </div>
            <div className="flex flex-col items-center gap-2 text-center">
              <div className="flex h-32 items-center justify-around">
                <svg
                  width="86"
                  height="78"
                  viewBox="0 0 86 78"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.9978 2.88128L43.4909 15.3764C44.8868 16.3734 44.8868 18.448 43.4909 19.4451L25.9978 31.9402C25.1285 32.5611 23.9608 32.5611 23.0916 31.9402L5.59844 19.4451C4.20257 18.448 4.20257 16.3734 5.59844 15.3764L23.0916 2.88128C23.9608 2.26039 25.1285 2.26039 25.9978 2.88128Z"
                    stroke="#F43F5E"
                    strokeWidth="3"
                  />
                  <path
                    d="M49.1697 16C48.6174 16 48.1697 16.4477 48.1697 17C48.1697 17.5523 48.6174 18 49.1697 18V16ZM65.1697 33L70.9432 23H59.3962L65.1697 33ZM49.1697 18H61.1697V16H49.1697V18ZM64.1697 21V24H66.1697V21H64.1697ZM61.1697 18C62.8265 18 64.1697 19.3431 64.1697 21H66.1697C66.1697 18.2386 63.9311 16 61.1697 16V18Z"
                    fill="#F43F5E"
                  />
                  <path
                    d="M26.1697 36C26.1697 35.4477 25.722 35 25.1697 35C24.6174 35 24.1697 35.4477 24.1697 36L26.1697 36ZM25.1697 55L30.9432 45L19.3962 45L25.1697 55ZM24.1697 36L24.1697 46L26.1697 46L26.1697 36L24.1697 36Z"
                    fill="#F43F5E"
                  />
                  <rect
                    x="4.65186"
                    y="58.1071"
                    width="39.7857"
                    height="18.8929"
                    rx="3"
                    fill="#FFE4E6"
                    stroke="#F43F5E"
                    strokeWidth="2"
                  />
                  <rect
                    x="45.0447"
                    y="35.8214"
                    width="39.7857"
                    height="18.8929"
                    rx="3"
                    fill="#FFE4E6"
                    stroke="#F43F5E"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              <p className="pt-5">
                <strong>Автоматизация бизнес процессов</strong>
              </p>
              <p>
                Настройка цепочек действий, проверок и зависимостей. Гибкость и
                эффективность.
              </p>
            </div>
            <div className="flex flex-col items-center gap-2 text-center">
              <div className="flex h-32 items-center justify-around">
                <svg
                  width="76"
                  height="59"
                  viewBox="0 0 76 59"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M49.5491 43.549C48.9968 43.549 48.5491 43.9967 48.5491 44.549C48.5491 45.1013 48.9968 45.549 49.5491 45.549V43.549ZM75.5088 44.549L69.7353 38.7755L63.9618 44.549L69.7353 50.3225L75.5088 44.549ZM49.5491 45.549H69.7353V43.549H49.5491V45.549Z"
                    fill="#F43F5E"
                  />
                  <path
                    d="M49.5491 14.3137C48.9968 14.3137 48.5491 14.7614 48.5491 15.3137C48.5491 15.866 48.9968 16.3137 49.5491 16.3137V14.3137ZM75.5088 15.3137L69.7353 9.54022L63.9618 15.3137L69.7353 21.0872L75.5088 15.3137ZM49.5491 16.3137L69.7353 16.3137V14.3137L49.5491 14.3137V16.3137Z"
                    fill="#F43F5E"
                  />
                  <path
                    d="M49.5 29C48.9477 29 48.5 29.4477 48.5 30C48.5 30.5523 48.9477 31 49.5 31V29ZM64.1667 30C64.1667 32.9455 66.5545 35.3333 69.5 35.3333C72.4455 35.3333 74.8333 32.9455 74.8333 30C74.8333 27.0545 72.4455 24.6667 69.5 24.6667C66.5545 24.6667 64.1667 27.0545 64.1667 30ZM49.5 31L69.5 31V29L49.5 29V31Z"
                    fill="#F43F5E"
                  />
                  <rect
                    x="36.5883"
                    y="1"
                    width="56.1723"
                    height="35.5882"
                    rx="3"
                    transform="rotate(90 36.5883 1)"
                    fill="#FFE4E6"
                    stroke="#F43F5E"
                    strokeWidth="2"
                  />
                  <path
                    d="M11 45L26.3137 45"
                    stroke="#F43F5E"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M11 30L26.3137 30"
                    stroke="#F43F5E"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M11 15L26.3137 15"
                    stroke="#F43F5E"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <p className="pt-5">
                <strong>Управленческий учет</strong>
              </p>
              <p>
                Вся информация о проектах, бюджетах, поставщиках. Эффективное
                управление.
              </p>
            </div>
            <div className="flex flex-col items-center gap-2 text-center">
              <div className="flex h-32 items-center justify-around">
                <svg
                  width="81"
                  height="50"
                  viewBox="0 0 81 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M44 48.0001L51.6767 27.3942C52.5959 24.9267 55.6073 24.0052 57.75 25.5357L63.4748 29.6249C65.5467 31.1048 68.4556 30.298 69.4692 27.9624L79 6"
                    stroke="#F43F5E"
                    strokeWidth="3"
                    strokeLinecap="round"
                  />
                  <rect
                    x="37"
                    y="1"
                    width="48"
                    height="8"
                    rx="3"
                    transform="rotate(90 37 1)"
                    fill="#FFE4E6"
                    stroke="#F43F5E"
                    strokeWidth="2"
                  />
                  <rect
                    x="23"
                    y="9"
                    width="40"
                    height="8"
                    rx="3"
                    transform="rotate(90 23 9)"
                    stroke="#F43F5E"
                    strokeWidth="2"
                  />
                  <rect
                    x="9"
                    y="20"
                    width="29"
                    height="8"
                    rx="3"
                    transform="rotate(90 9 20)"
                    fill="#FFE4E6"
                    stroke="#F43F5E"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              <p className="pt-5">
                <strong>Построение отчетов</strong>
              </p>
              <p>
                Вся информация о проектах, по любым срезам данных. Полная
                картина за любой период.
              </p>
            </div>
          </div>
        </Wrapper>
        <FormRequest full={false} />
        <Wrapper>
          <h3>
            Мы объединяем все бизнес процессы в одной системе OCTOS, что
            позволяет в режиме онлайн видеть общую картину по работе компании, а
            также анализировать данные в любом срезе. Кроме того, мы
            предоставляем возможность синхронизации со сторонними системами при
            необходимости.
          </h3>
        </Wrapper>
        <Wrapper>
          <div className="flex flex-col">
            <img
              className="object-cover min-h-[100px] lg:h-full w-full max-w-[1024px] overflow-hidden self-center"
              src={octos}
              alt="Модули"
            ></img>
          </div>
        </Wrapper>
        <Wrapper>
          <ul className="ulRingRose">
            <div className="flex flex-col gap-5">
              <li>
                <strong>Документы и справочники</strong>- набор документов и
                справочников, который создается для конкретной предметной
                области.
              </li>
              <li>
                <strong>Маршрутизация</strong>- система маршрутизации позволяет
                настроить проведение документов по статусам цепочки
                согласований, а также дает возможность: автоматически продвигать
                ожидающие документы (механизм PUSHER-WAITER), опираясь на
                вычисляемые свойства документов, осуществлять условные и
                безусловные переходы в другие статусы, выполнять функции,
                оповещать пользователей о необходимых действиях в системе и др.
              </li>
              <li>
                <strong>Правораспределение</strong>- гибкость разграничения
                доступа к данным системы обеспечивается трехуровневой системой
                правораспределения:
                <ul>
                  <li>
                    - Системное правораспределение - уровень всей системы;
                  </li>
                  <li>
                    - Функциональное правораспределение - уровень документов
                    системы;
                  </li>
                  <li>
                    - Статусное правораспределение - уровень статусов цепочек
                    подписания документов.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Отчетность</strong>- система позволяет настраивать
                формирование отчетов по всем возможным срезам данных, на которых
                опирается отчет (OLAP).
              </li>
              <li>
                <strong>Логирование</strong>- модуль обеспечивает постоянную
                запись всех действий пользователей в системе.
              </li>
              <li>
                <strong>Синхронизация с внешними системами</strong>- модуль
                обеспечивает двустороннюю синхронизацию с внешними системами по
                протоколу ODATA.
              </li>
            </div>
          </ul>
        </Wrapper>
      </div>

      <FormContactUs full={false} />
    </div>
  );
};
