import React from "react";
import { PageHeader } from "../../../components/PageHeader";
import { HardwareMaintenanceContent } from "./HardwareMaintenanceContent";
import { Advantages } from "../../../components/Advantages";
import { Wrapper } from "../../../components/Wrapper";
import { FormContactUs } from "../../../components/Forms/FormContactUs";

export const HardwareMaintenance = () => {
  window.scroll(0, 0);
  return (
    <div>
      <PageHeader pageName="Обслуживание IT инфраструктуры" />
      <Wrapper>
        <HardwareMaintenanceContent />
        <Advantages />
        <FormContactUs />
      </Wrapper>
    </div>
  );
};
