export const navItems = [
  {
    text: "Услуги",
    link: "/services",
  },
  {
    text: "IT обслуживание",
    // link: "/support",
    menuWidth: "w-80",
    menuItems: [
      {
        text: "Поддержка пользователей",
        link: "/it-maintenance/user-support",
        icon: "",
      },
      {
        text: "Обслуживание IT инфраструктуры",
        link: "/it-maintenance/hardware-maintenance",
        icon: "",
      },
      {
        text: "Резервное копирование и архивация данных",
        link: "/it-maintenance/data-backup",
        icon: "",
      },
    ],
  },
  {
    text: "Системы автоматизации",
    // link: "/systems",
    menuWidth: "w-80",
    menuItems: [
      { text: "Автоматизация бизнес процессов", link: "/bpm", icon: "" },
      { text: "1C сопровождение", link: "/support-1s", icon: "" },
    ],
  },
  {
    text: "Облачные системы",
    // link: "/clouds",
    menuWidth: "w-72",
    menuItems: [
      {
        text: "Миграция в облако",
        link: "/services/cloud-migration",
        icon: "",
      },
    ],
  },
];
