import React from "react";
import { Wrapper } from "./Wrapper";
import { Link } from "react-router-dom";

export const TopPanel = () => {
  return (
    <div className="bg-zinc-700 hidden lg:flex">
      <Wrapper>
        <div className="flex justify-end items-center space-x-8 py-2 text-white text-sm">
          <Link to="/about-us">О нас</Link>
          <Link to="/partner-program">Партнерская программа</Link>
          <Link to="/vacancies">Вакансии</Link>
        </div>
      </Wrapper>
    </div>
  );
};
