import React from "react";
import { Link } from "react-router-dom";

export const NavItemMenu = ({ item, click }) => {
  return item.menuItems.map((item) => (
    <ul key={item.text} className="items-center ">
      {/* <object> */}
      <Link to={item.link}>
        <li onClick={() => click(false)} className="py-1 hover:text-rose-500">
          {item.text}
        </li>
      </Link>
      {/* </object> */}
    </ul>
  ));
};
