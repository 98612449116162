import React, { useState } from "react";
import { Link } from "react-router-dom";

export const NavMobileItem = ({ closeMenu, item }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div onClick={() => item.menuItems && setOpen(!isOpen)}>
      <div className="flex space-x-2 py-4 cursor-pointer items-center">
        <span className="font-bold  hover:text-rose-500 uppercase">
          {item.text}
        </span>
        {item.menuItems && !isOpen && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="size-5"
          >
            <path
              fillRule="evenodd"
              d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {item.menuItems && isOpen && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="size-5"
          >
            <path
              fillRule="evenodd"
              d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </div>
      {isOpen && (
        <div className={`flex flex-col pl-4`}>
          {item.menuItems.map((item) => (
            <ul key={item.text} className="items-center ">
              <object>
                <Link to={item.link}>
                  <li
                    onClick={() => closeMenu()}
                    className="py-3 text-neutral-300 hover:text-rose-500"
                  >
                    {item.text}
                  </li>
                </Link>
              </object>
            </ul>
          ))}
        </div>
      )}
    </div>
  );
};
