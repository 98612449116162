import React from "react";
import { createPortal } from "react-dom";

export const Modal = ({
  children,
  textHeader = "Модальное окно",
  fixedHeader = false,
  onClose,
}) => {
  return createPortal(
    <div id="modal">
      <div
        onClick={onClose}
        className="h-[100vh] w-[100vw] bg-black/60 fixed top-0 left-0 flex items-center justify-center z-50"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="relative flex flex-col rounded-lg bg-white shadow-2xl shadow-black/50 w-full sm:max-w-[600px] max-h-screen overflow-auto overscroll-none"
        >
          <header className="flex px-8 p-6 justify-center border-b">
            <div className="text-2xl">{textHeader}</div>
            <svg
              onClick={onClose}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-7 cursor-pointer absolute top-4 right-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </header>
          <div
            className={`flex h-full px-8 pt-6 pb-8 bg-neutral-100 ${
              fixedHeader && "overflow-auto"
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};
