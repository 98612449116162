import React, { useState } from "react";
import { Modal } from "./Modal";
import { FormQuestion } from "../components/Forms/FormComponents/FormQuestion";

export const MailBaner = () => {
  const [isModal, setModal] = useState(false);
  return (
    <>
      <button
        onClick={() => setModal(true)}
        className="w-[56px] h-[56px] flex justify-center items-center hover:cursor-pointer fixed bottom-5 right-3 rounded-tl-[22px] rounded-br-[22px] bg-rose-500 hover:bg-rose-600 shadow-lg hover:scale-110 duration-300"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          // fill="currentColor"
          className="size-8 fill-black"
        >
          <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
          <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
        </svg>
      </button>
      {isModal && (
        <Modal onClose={() => setModal(false)} textHeader="Отправить заявку">
          <FormQuestion onClose={() => setModal(false)} />
        </Modal>
      )}
    </>
  );
};
