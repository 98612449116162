import React from "react";
import { UserSupportContetnt } from "./UserSupportContetnt";
import { Advantages } from "../../../components/Advantages";
import { Wrapper } from "../../../components/Wrapper";
import { PageHeader } from "../../../components/PageHeader";
import { FormContactUs } from "../../../components/Forms/FormContactUs";

export const UserSupport = () => {
  window.scroll(0, 0);
  return (
    <div>
      <PageHeader pageName="Поддержка пользователей" />
      <Wrapper>
        <UserSupportContetnt />
        <Advantages />
        <FormContactUs />
      </Wrapper>
    </div>
  );
};
