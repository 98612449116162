import React from "react";
import contactUs from "../../images/contact-us.jpg";
import { FormQuestion } from "./FormComponents/FormQuestion";

export const FormContactUs = ({ full = true, my = true }) => {
  return (
    <div className={`${!full && "w-full sm:px-5 md:px-10 lg:px-20"}`}>
      <div
        className={`grid lg:grid-cols-2 ${
          !full && "sm:rounded-xl"
        } bg-neutral-100 ${my && "my-10"}`}
      >
        <div
          className={`${
            !full && "sm:rounded-t-xl lg:rounded-none lg:rounded-l-xl"
          } overflow-hidden saturate-50`}
        >
          <img
            // className="h-44 sm:h-60 md:h-full object-cover object-center"
            className="h-44 sm:h-60 md:h-full w-full object-cover object-right"
            src={contactUs}
            alt="Поддержка IT инфраструктуры"
          ></img>
        </div>
        <div className="px-5 py-8 sm:p-10">
          <FormQuestion />
        </div>
      </div>
    </div>
  );
};
