import React from "react";
import { PageHeader } from "../../../components/PageHeader";
import { Wrapper } from "../../../components/Wrapper";
import { CloudMigrationContent } from "./CloudMigrationContent";
import { Advantages } from "../../../components/Advantages";
import { FormContactUs } from "../../../components/Forms/FormContactUs";

export const CloudMigration = () => {
  window.scroll(0, 0);
  return (
    <div>
      <PageHeader pageName="Облачные технологии" />
      <Wrapper>
        <CloudMigrationContent />
        <Advantages />
        <FormContactUs />
      </Wrapper>
    </div>
  );
};
