import React from "react";
import { PageHeader } from "../../components/PageHeader";
import { Wrapper } from "../../components/Wrapper";
import { FormRequest } from "../../components/Forms/FormRequest";
import { FormContactUs } from "../../components/Forms/FormContactUs";

export const Support1s = () => {
  window.scroll(0, 0);
  return (
    <>
      <PageHeader pageName="Сопровождение 1С" />
      <div className="flex flex-col py-10 gap-10">
        <Wrapper>
          <h3>
            Предлагаем полный спектр услуг по техническому обслуживанию и
            сопровождению программных продуктов 1С. Мы гарантируем стабильную
            работу вашей системы, своевременные обновления и оперативное решение
            любых возникающих проблем.
          </h3>
        </Wrapper>
        <FormRequest full={false} />
        <Wrapper>
          <section>
            <h2 className="pb-6">Наши услуги по сопровождению 1С:</h2>
            <ul className="ulRingRose">
              <div className="flex flex-col gap-5">
                <li>
                  <strong>Техническое сопровождение 1С:</strong> Обеспечим
                  бесперебойную работу ваших систем 1С, включая настройку,
                  администрирование и управление базами данных. Наши специалисты
                  решат любые вопросы, связанные с работой серверов и клиентских
                  рабочих станций.
                </li>
                <li>
                  <strong>Обновления и миграции 1С:</strong> Проводим плановые
                  обновления и миграции на новые версии программ 1С, включая
                  адаптацию функционала под новые требования бизнеса и
                  законодательства.
                </li>
                <li>
                  <strong>Консультации и настройка 1С под ваши задачи:</strong>{" "}
                  Наши консультанты помогут адаптировать программное обеспечение
                  1С под специфические потребности вашего бизнеса: настройка и
                  внедрение новых подсистем, автоматизация процессов, интеграция
                  с другими системами.
                </li>
                <li>
                  <strong>Разработка и доработка решений 1С:</strong> Мы
                  предлагаем услуги по индивидуальной доработке функционала,
                  добавлению новых отчетов, автоматизации процессов и созданию
                  специализированных подсистем, которые удовлетворяют уникальные
                  потребности вашего бизнеса.
                </li>
                <li>
                  <strong>Техническая поддержка 1С:</strong> Оперативная помощь
                  по телефону, электронной почте или через систему удаленной
                  поддержки. Мы готовы оперативно решать проблемы с программным
                  обеспечением, минимизируя простой вашего бизнеса.
                </li>
                <li>
                  <strong>Аудит и оптимизация работы 1С:</strong> Проведем аудит
                  текущих настроек системы, проверим ее производительность и
                  безопасность. На основе полученных данных мы предложим
                  оптимизацию процессов для повышения эффективности работы вашей
                  системы.
                </li>
              </div>
            </ul>
          </section>
        </Wrapper>
      </div>
      <FormContactUs full={false} />
    </>
  );
};
