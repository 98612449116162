import React from "react";
import outsourcing from "./outsourcing.png";
import { FormRequest } from "../../../components/Forms/FormRequest";
import { ContextLink } from "../../../components/ContextLink";
export const OutsourcingContent = () => {
  return (
    <div>
      <section className="grid lg:grid-cols-2 gap-10 py-10 lg:pt-20">
        <img
          className="object-cover min-h-[100px] max-h-[350px] lg:max-h-[500px] lg:h-full w-full rounded-lg overflow-hidden"
          src={outsourcing}
          alt="Поддержка IT инфраструктуры"
        ></img>
        <div>
          <h1 className="text-3xl font-bold text-black pb-6">
            Предлагаем комплексное IT обслуживание вашей организации
          </h1>
          <p>
            <strong>
              IT-аутсорсинг — это стратегическое решение для бизнеса, которое
              позволяет передать управление IT-услугами внешним специалистам,
              оптимизируя затраты и повышая эффективность.
            </strong>
          </p>
        </div>
      </section>

      <FormRequest />

      <h2>Услуги аутсорсинга от DGS:</h2>
      <ul>
        <li>
          <h3>Управление IT-инфраструктурой:</h3> Мы{" "}
          <ContextLink to="/services/hardware-maintenance">
            обеспечиваем надежную работу всех компонентов вашей IT-системы
          </ContextLink>{" "}
          — от серверов и рабочих станций до сетевых устройств и приложений. Мы
          берём на себя все задачи по мониторингу, обновлению и оптимизации
          инфраструктуры, позволяя вам сосредоточиться на развитии бизнеса.
        </li>
        <li>
          <h3>Техническая поддержка:</h3> Мы предоставляем техническую{" "}
          <ContextLink to="/services/user-support">
            поддержку ваших сотрудников и пользователей
          </ContextLink>
          , решая возникающие IT-проблемы в любое время. Наша команда быстро
          реагирует на запросы и помогает решить вопросы любой сложности — от
          настройки оборудования до устранения сбоев в работе ПО.
        </li>
        <li>
          <h3>Управление безопасностью:</h3> Обеспечиваем защиту ваших данных,
          сети и систем от внешних угроз. Мы внедряем стратегии
          кибербезопасности, проводим регулярные аудит безопасности и
          обеспечиваем защиту от вирусов, атак и утечек информации.
        </li>
        <li>
          <h3>Поддержка и развитие ПО:</h3> Мы управляем программным
          обеспечением вашей компании, осуществляем его настройку, обновление и
          интеграцию. Также мы предоставляем консультации по выбору и внедрению
          новых программных решений, соответствующих вашим бизнес-целям.
        </li>
        <li>
          <h3>Облачные решения:</h3> Предлагаем{" "}
          <ContextLink to="/services/cloud-migration">
            поддержку и внедрение облачных технологий
          </ContextLink>{" "}
          для хранения данных, работы с приложениями и обеспечения гибкости в
          управлении IT-ресурсами. Мы поможем вам выбрать оптимальное облачное
          решение, которое обеспечит доступность и безопасность данных.
        </li>
      </ul>

      <h2 className="pt-10 pb-5">Преимущества IT-аутсорсинга:</h2>
      <ul className="ulRingRose">
        <div className="flex flex-col gap-5">
          <li>
            <strong>Снижение затрат:</strong> Перенос IT-услуг на аутсорсинг
            позволяет снизить расходы на содержание внутреннего IT-отдела и
            инфраструктуры.
          </li>
          <li>
            <strong>Повышение эффективности:</strong> Мы берем на себя все
            технические задачи, что позволяет вашему бизнесу сосредоточиться на
            ключевых направлениях.
          </li>
          <li>
            <strong>Доступ к экспертизе:</strong> Мы предоставляем вам доступ к
            высококвалифицированным специалистам с опытом работы в различных
            областях IT.
          </li>
          <li>
            <strong>Гибкость и масштабируемость:</strong> Мы адаптируем решения
            под нужды вашего бизнеса, легко масштабируя услуги по мере роста
            компании.
          </li>
          <li>
            <strong>Надежность и безопасность:</strong> Мы обеспечиваем
            бесперебойную работу ваших IT-систем и защиту данных, минимизируя
            риски и обеспечивая соблюдение стандартов безопасности.
          </li>
        </div>
      </ul>

      <h3 className="pt-8">
        IT-аутсорсинг с нашей компанией — это надежный способ повышения
        эффективности и безопасности IT-операций при минимальных затратах,
        позволяющий вам сосредоточиться на развитии бизнеса и достижении
        стратегических целей.
      </h3>
    </div>
  );
};
