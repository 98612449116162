import { useMatches } from "react-router-dom";

export const BreadCrumbs = () => {
  let matches = useMatches();
  // console.log(matches);
  let crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => match.handle.crumb(match.data));

  return (
    <ol className="flex flex-row text-sm text-neutral-700 text-ellipsis">
      {crumbs.map((crumb, index) => (
        <li className="flex flex-row" key={index}>
          {index > 0 && <div className="px-3 text-neutral-400">/</div>}
          <div className="hover:text-rose-500 text-nowrap text-ellipsis">
            {crumb}
          </div>
        </li>
      ))}
    </ol>
  );
};
