import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Wrapper } from "../Wrapper";
import { NavItem } from "./NavItem";
import { navItems } from "./navItems";
// import { SocialIcons } from "../SocialIcons";

export const Nav = () => {
  //StickyNav
  // let location = useLocation();
  const [isNavSticky, setNavSticky] = useState(false);
  const changeNavCss = () => {
    window.scrollY > 200 ? setNavSticky(true) : setNavSticky(false);
  };
  // window.addEventListener("scroll", changeNavCss);

  useEffect(() => {
    window.addEventListener("scroll", changeNavCss);
    return () => window.removeEventListener("scroll", changeNavCss);
  }, []);

  return (
    <nav
      className={
        isNavSticky
          ? "sticky top-0 shadow-lg shadow-black/30 z-50 bg-white hidden lg:flex select-none"
          : "hidden lg:flex bg-white select-none"
      }
    >
      <Wrapper>
        <div
          // className={`${
          //   !isNavSticky && location.pathname === "/" && "border-b"
          // } flex flex-row items-center justify-between`}
          className={`flex flex-row items-center justify-between`}
        >
          {navItems.map((item) =>
            item.link ? (
              <Link key={item.text} to={item.link}>
                <NavItem item={item} />
              </Link>
            ) : (
              <NavItem key={item.text} item={item} />
            )
          )}
          {/* <div className="pl-20">
            <SocialIcons />
          </div> */}
        </div>
      </Wrapper>
    </nav>
  );
};
