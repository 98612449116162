import React from "react";
import aboutUs from "./team_h.jpg";
import aboutUs_w from "./team_w.jpg";
import { useLocation } from "react-router-dom";

export const AboutUsContent = () => {
  let location = useLocation();
  return (
    <div className="flex flex-col my-16">
      {location.pathname !== "/about-us" && (
        <h1 className="mb-10 uppercase">О нас</h1>
      )}
      <div className="grid lg:grid-cols-2 gap-10 lg:gap-0">
        <div className="lg:pr-10 order-last lg:order-first ">
          <div className="flex flex-col gap-5">
            <p>
              <strong>Мы</strong> команда профессионалов в области
              информационных технологий, более 15 лет работающая на стыке
              инноваций и практических решений для бизнеса.
            </p>
            <p>
              <strong>Наши услуги</strong> охватывают весь спектр IT-услуг, от
              комплексной технической поддержки и настройки инфраструктуры до
              разработки программного обеспечения и консультаций.
            </p>
            <p>
              <strong>Наша миссия</strong> — помочь компаниям адаптироваться к
              современным технологическим вызовам, повысить их эффективность и
              обеспечить устойчивость в мире цифровых трансформаций.
            </p>
            <p>
              <strong>
                Мы умеем слушать и слышать наших клиентов, а благодаря богатому
                опыту работы в различных отраслях, быстро выявляем потребности и
                разрабатываем решения, идеально соответствующие вашим задачам.
              </strong>
            </p>
          </div>
          <h3 className="pt-16 text-xl uppercase pb-8">Наши принципы</h3>

          <ul className="ulRingRose">
            <div className="flex flex-col gap-5">
              <li>
                <strong>Индивидуальный подход:</strong> мы разрабатываем
                персонализированные решения, которые отвечают потребностям
                вашего бизнеса.
              </li>
              <li>
                <strong>Надежность и безопасность:</strong> мы гарантируем
                высокое качество услуг и безопасность данных клиентов.
              </li>
              <li>
                <strong>Инновации:</strong> мы внедряем новейшие технологии,
                чтобы наши клиенты получали конкурентные преимущества в быстро
                меняющемся цифровом мире.
              </li>
            </div>
          </ul>
        </div>
        {/* Картинка */}
        <div className="relative overflow-hidden rounded-sm">
          <img
            className="absolute hidden lg:flex object-cover w-full h-full xl:max-h-[848px] object-center saturate-[.25]"
            src={aboutUs}
            alt="Команда"
          ></img>
          <img
            className="h-44 sm:h-60 md:h-80 lg:hidden object-cover w-full object-center saturate-[.25]"
            src={aboutUs_w}
            alt="Команда"
          ></img>
        </div>
      </div>
    </div>
  );
};
