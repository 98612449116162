import React from "react";
import teh_podderjka from "./teh-podderjka.jpg";
import { FormRequest } from "../../../components/Forms/FormRequest";
import { ContextLink } from "../../../components/ContextLink";

export const UserSupportContetnt = () => {
  return (
    <div>
      <section className="grid lg:grid-cols-2 gap-10 py-10 lg:pt-20">
        <img
          className="object-cover min-h-[100px] max-h-[350px] lg:max-h-[500px] lg:h-full w-full rounded-lg overflow-hidden"
          src={teh_podderjka}
          alt="Поддержка IT инфраструктуры"
        ></img>

        <div>
          <h1 className="text-3xl font-bold text-black pb-6">
            Мы придаем особое значение качественной и своевременной поддержке
            пользователей
          </h1>

          <p className="text-black">
            Надежная IT-инфраструктура и быстрая реакция на технические вопросы
            это залог успешной работы вашего бизнеса.
            <br />
            Гарантируем оперативную реакцию и высокое качество обслуживания, что
            позволяет нам не только решать текущие задачи, но и предупреждать
            возможные проблемы в будущем.
          </p>
        </div>
      </section>

      <FormRequest />

      <h2 className="pt-10">Описание услуги</h2>
      <p className="">
        Мы предоставляем высококачественную техническую поддержку,
        ориентированную на потребности вашего бизнеса. Наша команда готова
        оперативно реагировать на любые вопросы и помогать вам в решении
        технических задач на всех этапах взаимодействия — от консультаций до
        сложных технических решений.
      </p>

      <h2 className="pt-10">
        Организация линий поддержки пользователей в соответствии с ITSM и
        контролем выполнения обязательств по уровням сервиса (SLA)
      </h2>
      <p>
        Для эффективного решения запросов пользователей и обеспечения
        стабильности работы систем мы используем лучшие практики управления
        IT-сервисами, соответствующие стандартам ITIL (IT Service Management,
        ITSM). В соответствии с этим подходом, мы организуем многозвенную
        систему поддержки, включая:
      </p>
      <ul className="ulRingRose">
        <li>
          <strong>Первичную линию поддержки</strong> — для быстрого решения
          простых и стандартных запросов.
        </li>
        <li>
          <strong>Вторичную и третью линии</strong> — для решения более сложных
          технических проблем, требующих углубленной диагностики и экспертизы.
        </li>
      </ul>
      <p>
        Каждое обращение фиксируется в нашей системе тикетов, что позволяет
        отслеживать статус решения проблемы и соблюдать установленные сроки. Мы
        строго контролируем выполнение обязательств по уровням сервиса (SLA),
        гарантируя вам быстрый отклик и соблюдение договорных условий.
      </p>

      <h2 className="pt-10">Создание и ведение корпоративной базы знаний</h2>
      <p>
        Для вашего удобства мы регулярно обновляем и поддерживаем корпоративную{" "}
        <ContextLink to="/services/knowledge-base">базу знаний</ContextLink>,
        которая содержит полезную информацию, пошаговые инструкции и
        рекомендации по решению часто возникающих проблем. Эта база доступна для
        всех наших клиентов и помогает сократить время на поиск решения и
        повысить общую эффективность работы.
      </p>
      <p></p>

      <h2 className="pt-10">
        Удаленное администрирование с использованием программного комплекса
        1С-Коннект
      </h2>
      <p>
        Мы предлагаем услугу <strong>удаленного администрирования</strong> с
        помощью программного комплекса <strong>1С-Коннект</strong>. Это решение
        позволяет нашим специалистам оперативно и безопасно подключаться к вашим
        системам для диагностики, настройки и устранения неполадок без
        необходимости физического присутствия. Мы можем выполнять различные
        задачи — от настройки программного обеспечения и обслуживания баз данных
        до комплексного мониторинга и оптимизации работы вашей
        IT-инфраструктуры.
      </p>
      <p>
        Наши администраторы работают только с использованием надежных и
        проверенных инструментов, обеспечивающих высокий уровень безопасности и
        конфиденциальности данных.
      </p>
    </div>
  );
};
