import React from "react";
import { Wrapper } from "./Wrapper";
import { BreadCrumbs } from "./BreadCrumbs";

export const PageHeader = ({ pageName = "Заголовок страницы" }) => {
  return (
    <div className="bg-neutral-200 overflow-hidden">
      <Wrapper>
        <div className="flex flex-col items-start md:flex-row flex-wrap gap-y-8 justify-between md:items-center py-6 select-none">
          <div className="md:pr-20 font-medium text-3xl">{pageName}</div>
          <BreadCrumbs />
        </div>
      </Wrapper>
    </div>
  );
};
