import React from "react";
import { Link } from "react-router-dom";

export const PopularServiceCard = ({
  serviseName = "Услуга",
  serviseHref = "/404",
  children,
  img,
}) => {
  return (
    <Link to={serviseHref}>
      <div className="transition border group relative hover:shadow-lg overflow-hidden h-full">
        <div className="w-full h-[250px] overflow-hidden">
          <img
            className="transition duration-500 saturate-100 group-hover:saturate-100 group-hover:scale-110 object-cover w-full h-[250px] overflow-hidden"
            src={img}
            alt="Поддержка IT инфраструктуры"
          ></img>
        </div>
        <div className="px-5 py-10">
          <h1 className="text-xl font-semibold">{serviseName}</h1>
          <div className="py-2"></div>
          <p className="text-sm text-neutral-600">{children}</p>
        </div>
        <div className="transition md:hidden group-hover:flex absolute bottom-0 right-0 border-[32px] border-solid border-transparent border-r-[32px] border-r-solid border-r-rose-500 border-b-[32px] border-b-solid border-b-rose-500"></div>
        <div className="transition absolute bottom-[10px] right-[8px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            className="transition size-5 stroke-white md:stroke-neutral-500 group-hover:stroke-white absolute bottom-0 right-0"
          >
            <path
              // stroke-linecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </div>
    </Link>
  );
};
