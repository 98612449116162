import React from "react";
import { PageHeader } from "../components/PageHeader";

export const Error404 = () => {
  return (
    <>
      <PageHeader pageName="Страница не найдена... =(" />
      <div className="flex flex-col py-40 items-center flex-auto justify-around">
        <div className="grid justify-items-center gap-4">
          <span className="text-neutral-300 text-9xl">404</span>
          <span className="text-neutral-500">Данная страница не найдена!</span>
        </div>
      </div>
    </>
  );
};
