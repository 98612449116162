import React from "react";

export const SocialIcons = ({ iconsLight = false }) => {
  return (
    <div className="flex flex-row gap-4 items-center justify-between>">
      <svg
        className={`${
          iconsLight ? "stroke-zinc-200" : "stroke-neutral-600"
        } hover:stroke-rose-500 cursor-pointer`}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 3H21M21 3V8M21 3L15 9M5 3C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V6C3 14.284 9.716 21 18 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V15.72C20.9998 15.5102 20.9337 15.3058 20.8109 15.1356C20.6882 14.9655 20.515 14.8383 20.316 14.772L15.823 13.274C15.5947 13.1981 15.3466 13.2071 15.1244 13.2993C14.9021 13.3915 14.7205 13.5607 14.613 13.776L13.483 16.033C11.0343 14.9264 9.07312 12.9649 7.967 10.516L10.224 9.388C10.4393 9.28045 10.6085 9.0989 10.7007 8.87665C10.7929 8.65439 10.8019 8.40634 10.726 8.178L9.228 3.683C9.16148 3.48398 9.03404 3.31093 8.86372 3.18835C8.6934 3.06577 8.48884 2.99988 8.279 3H5Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <svg
        className={`${
          iconsLight ? "fill-zinc-200" : "fill-neutral-600"
        } hover:fill-rose-500 cursor-pointer`}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10V10ZM10.3587 7.3825C9.38625 7.7875 7.44125 8.625 4.52625 9.895C4.05375 10.0825 3.805 10.2675 3.7825 10.4475C3.745 10.7512 4.12625 10.8713 4.645 11.035L4.86375 11.1038C5.37375 11.27 6.06125 11.4638 6.4175 11.4713C6.7425 11.4788 7.10375 11.3463 7.5025 11.0713C10.2262 9.2325 11.6325 8.30375 11.72 8.28375C11.7825 8.26875 11.87 8.25125 11.9275 8.30375C11.9862 8.355 11.98 8.45375 11.9738 8.48C11.9363 8.64125 10.44 10.0312 9.66625 10.7512C9.425 10.9762 9.25375 11.135 9.21875 11.1713C9.14168 11.25 9.06333 11.3275 8.98375 11.4038C8.50875 11.8613 8.15375 12.2038 9.0025 12.7638C9.41125 13.0338 9.73875 13.255 10.065 13.4775C10.42 13.72 10.775 13.9613 11.235 14.2638C11.3512 14.3388 11.4638 14.42 11.5725 14.4975C11.9863 14.7925 12.36 15.0575 12.8188 15.015C13.0863 14.99 13.3625 14.74 13.5025 13.99C13.8338 12.2188 14.485 8.3825 14.635 6.80125C14.6442 6.6698 14.6387 6.53775 14.6187 6.4075C14.607 6.3024 14.5561 6.20557 14.4763 6.13625C14.3625 6.05774 14.2269 6.01706 14.0887 6.02C13.7137 6.02625 13.135 6.2275 10.3587 7.3825V7.3825Z" />
      </svg>

      <svg
        className={`${
          iconsLight ? "fill-zinc-200" : "fill-neutral-600"
        } hover:fill-rose-500 cursor-pointer`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="3 3 25 25"
        width="21"
        height="21"
      >
        <path d="M 15 3 C 8.373 3 3 8.373 3 15 C 3 17.251208 3.6323415 19.350068 4.7109375 21.150391 L 3.1074219 27 L 9.0820312 25.431641 C 10.829354 26.425062 12.84649 27 15 27 C 21.627 27 27 21.627 27 15 C 27 8.373 21.627 3 15 3 z M 10.892578 9.4023438 C 11.087578 9.4023438 11.287937 9.4011562 11.460938 9.4101562 C 11.674938 9.4151563 11.907859 9.4308281 12.130859 9.9238281 C 12.395859 10.509828 12.972875 11.979906 13.046875 12.128906 C 13.120875 12.277906 13.173313 12.453437 13.070312 12.648438 C 12.972312 12.848437 12.921344 12.969484 12.777344 13.146484 C 12.628344 13.318484 12.465078 13.532109 12.330078 13.662109 C 12.181078 13.811109 12.027219 13.974484 12.199219 14.271484 C 12.371219 14.568484 12.968563 15.542125 13.851562 16.328125 C 14.986562 17.342125 15.944188 17.653734 16.242188 17.802734 C 16.540187 17.951734 16.712766 17.928516 16.884766 17.728516 C 17.061766 17.533516 17.628125 16.864406 17.828125 16.566406 C 18.023125 16.268406 18.222188 16.319969 18.492188 16.417969 C 18.766188 16.515969 20.227391 17.235766 20.525391 17.384766 C 20.823391 17.533766 21.01875 17.607516 21.09375 17.728516 C 21.17075 17.853516 21.170828 18.448578 20.923828 19.142578 C 20.676828 19.835578 19.463922 20.505734 18.919922 20.552734 C 18.370922 20.603734 17.858562 20.7995 15.351562 19.8125 C 12.327563 18.6215 10.420484 15.524219 10.271484 15.324219 C 10.122484 15.129219 9.0605469 13.713906 9.0605469 12.253906 C 9.0605469 10.788906 9.8286563 10.071437 10.097656 9.7734375 C 10.371656 9.4754375 10.692578 9.4023438 10.892578 9.4023438 z" />
      </svg>
    </div>
  );
};
