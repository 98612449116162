import React from "react";
import new_service from "./new-service.jpg";
import { FormRequest } from "../../../components/Forms/FormRequest";
export const NewServicesContent = () => {
  return (
    <div>
      <section className="grid lg:grid-cols-2 gap-10 py-10 lg:pt-20">
        <img
          className="object-cover h-full w-full rounded-lg overflow-hidden"
          src={new_service}
          alt="Поддержка IT инфраструктуры"
        ></img>
        <div>
          <h1 className="text-3xl font-bold text-black pb-6">
            Построение ИТ-инфраструктуры с нуля
          </h1>
          <p className="text-black">
            Создание ИТ-инфраструктуры с нуля — это ключевая задача для
            компаний, которые только начинают свой путь в цифровом
            бизнес-пространстве, а также для тех, кто решает модернизировать
            устаревшие системы и перейти на новые технологии. Мы предлагаем
            полный спектр услуг по проектированию, внедрению и оптимизации
            ИТ-инфраструктуры, начиная с разработки стратегии и заканчивая
            запуском всех необходимых систем для работы вашего бизнеса.
          </p>
        </div>
      </section>

      <FormRequest />

      <h1 className="pb-6 pt-4">Этапы построения ИТ-инфраструктуры с нуля:</h1>
      <ol className="ulRingRose">
        <div className="flex flex-col gap-5">
          <li>
            <strong>Анализ потребностей бизнеса:</strong> Мы начинаем с
            глубокого анализа ваших текущих процессов и требований. Это помогает
            нам понять, какие технологии и решения подойдут для вашей компании в
            долгосрочной перспективе, а также определить оптимальные подходы для
            обеспечения масштабируемости, безопасности и производительности.
          </li>
          <li>
            <strong>Проектирование ИТ-архитектуры:</strong> На основе полученных
            данных мы разрабатываем архитектуру, включающую выбор серверного
            оборудования, сетевых компонентов, программного обеспечения и
            облачных решений, которые идеально соответствуют задачам вашего
            бизнеса. Мы учитываем все аспекты: от потребности в вычислительных
            мощностях до требований безопасности и защиты данных.
          </li>
          <li>
            <strong>Внедрение и интеграция:</strong> На этом этапе мы выполняем
            полное развертывание всех систем — от серверов и сетей до приложений
            и баз данных. Мы гарантируем, что все компоненты интегрируются в
            единую и слаженную инфраструктуру, которая будет работать без сбоев
            и соответствовать всем требованиям.
          </li>
          <li>
            <strong>Обеспечение безопасности и резервного копирования:</strong>{" "}
            Уделяем особое внимание вопросам безопасности. Мы внедряем решения
            для защиты данных, защиты от внешних угроз, а также систему
            резервного копирования и восстановления для предотвращения потери
            данных.
          </li>
          <li>
            <strong>Тестирование и оптимизация:</strong> После развертывания мы
            проводим всестороннее тестирование системы на предмет
            производительности, стабильности и безопасности. Мы оптимизируем
            каждый элемент инфраструктуры для обеспечения максимальной
            эффективности.
          </li>
          <li>
            <strong>Поддержка и обслуживание:</strong> После успешного внедрения
            мы предоставляем услуги по технической поддержке и обслуживанию
            системы, чтобы она всегда работала на пике своей производительности.
            Мы также обеспечиваем регулярное обновление системы и мониторинг для
            предотвращения возможных сбоев.
          </li>
        </div>
      </ol>

      <h2 className="pt-10">Внедряя ИТ-инфраструктуру с нами вы получаете:</h2>
      <ul>
        <div className="flex flex-col gap-5">
          <li>
            <h3>Индивидуальный подход:</h3> Мы разрабатываем ИТ-решения, которые
            полностью соответствуют специфике вашего бизнеса, учитывая все ваши
            нужды и цели.
          </li>
          <li>
            <h3>Масштабируемость:</h3> Создаём инфраструктуру, которая легко
            масштабируется и адаптируется под рост вашего бизнеса и изменения в
            его потребностях.
          </li>
          <li>
            <h3>Надёжность и безопасность:</h3> Обеспечиваем защиту данных и
            минимизацию рисков благодаря лучшим практикам в области безопасности
            и резервного копирования.
          </li>
          <li>
            <h3>Поддержка на всех этапах:</h3> Мы обеспечиваем не только
            построение ИТ-инфраструктуры, но и её дальнейшую поддержку,
            оптимизацию и развитие, помогая вам фокусироваться на развитии
            бизнеса.
          </li>
        </div>
      </ul>

      <p className="pt-10">
        <strong>
          Построение ИТ-инфраструктуры с нуля с нашей компанией — это
          гарантированно успешный старт для вашего бизнеса, который позволит вам
          эффективно работать в цифровом пространстве и адаптироваться к
          изменениям в будущем.
        </strong>
      </p>
    </div>
  );
};
