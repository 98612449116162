import React, { useEffect, useRef, useState } from "react";
import main from "../images/main_c.webp";

export const MainImage = () => {
  const [offsetY, setOffsetY] = useState(0);
  const mainPicRef = useRef();
  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };
  // console.log(offsetY);
  useEffect(() => {
    setTimeout(() => {
      mainPicRef.current.classList.remove("opacity-0");
    }, 100);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <img
      ref={mainPicRef}
      // className="object-cover w-full h-[120%] object-top -top-[140px] absolute -z-10 opacity-0 transition-opacity duration-[2000ms] saturate-[0.75]"
      className="object-cover w-full h-[120%] object-top -top-[140px] absolute -z-10 opacity-0 transition-opacity duration-[2000ms]"
      style={{
        transform: `translateY(${offsetY * 0.5}px)`,
        // transform: `translate3d(0,${offsetY * 0.5}px,0)`,
      }}
      src={main}
      alt="Фоновое изображение"
    ></img>
  );
};
