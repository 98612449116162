import React from "react";
// import { DGSButton } from "./DGSButton";
import { Wrapper } from "./Wrapper";
import { PopularServiceCard } from "./PopularServiceCard";
import { Link } from "react-router-dom";

import teh_podderjka from "../pages/servicePages/UserSupport/teh-podderjka.jpg";
import it_infrastruktura from "../pages/servicePages/HardwareMaintenance/it_infrastruktura.jpg";
import new_service from "../pages/servicePages/NewServices/new-service.jpg";
import cloud_migration from "../pages/servicePages/CloudMigration/cloudmigration.jpg";
import { AppearanceUp } from "./AppearanceUp";

export const PopularServices = () => {
  window.scroll(0, 0);
  return (
    <div>
      <Wrapper>
        <div className="flex flex-col pt-20 pb-10">
          <AppearanceUp>
            <h1 className="flex justify-self-center uppercase">
              Популярные услуги
            </h1>
          </AppearanceUp>
          <div className="py-14 grid sm:grid-cols-2 gap-10">
            <AppearanceUp>
              <PopularServiceCard
                serviseName="Поддержка пользователей"
                serviseHref="/services/user-support"
                img={teh_podderjka}
              >
                Эффективная техническая поддержка для решения любых IT-проблем.
                Включает создание и поддержку базы знаний для быстрого поиска
                решений, а также удаленное администрирование через 1С-Коннект
                для оперативного обслуживания систем.
              </PopularServiceCard>
            </AppearanceUp>
            <AppearanceUp>
              <PopularServiceCard
                serviseName="Обслуживание IT инфраструктуры"
                serviseHref="/services/hardware-maintenance"
                img={it_infrastruktura}
              >
                Комплексная поддержка офисного оборудования, персональных
                компьютеров, серверного и телекоммуникационного оборудования
                независимо от размера предприятия и количества задействованной
                техники.
              </PopularServiceCard>
            </AppearanceUp>
            <AppearanceUp>
              <PopularServiceCard
                serviseName="Построение ИТ инфраструктуры с нуля"
                serviseHref="/services/new-services"
                img={new_service}
              >
                Проектирование и внедрение ИТ-инфраструктуры, полностью
                соответствующей потребностям вашего бизнеса. Мы поможем вам
                создать масштабируемую, безопасную и высокоэффективную систему,
                обеспечив надежную работу всех элементов от серверов до
                приложений.
              </PopularServiceCard>
            </AppearanceUp>
            <AppearanceUp>
              <PopularServiceCard
                serviseName="Перенос инфраструктуры и сервисов в облако"
                serviseHref="/services/cloud-migration"
                img={cloud_migration}
              >
                Перенос вашей IT-инфраструктуры и сервисов в облако для
                повышения гибкости, снижения затрат и улучшения доступности. Мы
                обеспечиваем безопасный и бесперебойный переход, оптимизацию
                облачной среды и поддержку на всех этапах миграции.
              </PopularServiceCard>
            </AppearanceUp>
          </div>
          <Link to="/services">
            <div className="transition-all duration-300 text-red-500 text-lg font-bold hover:text-xl uppercase underline underline-offset-[5px] flex w-full justify-center">
              Все услуги
            </div>
          </Link>
        </div>
      </Wrapper>
    </div>
  );
};
