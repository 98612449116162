import React from "react";
import { PageHeader } from "../components/PageHeader";
import { Wrapper } from "../components/Wrapper";

export const Policy = () => {
  window.scroll(0, 0);
  return (
    <>
      <PageHeader pageName="Политика обработки персональных данных" />
      <Wrapper>
        <div className="py-10">
          <p>
            Предоставляя свои персональные данные Пользователь даёт согласие на
            обработку, хранение и использование своих персональных данных на
            основании ФЗ № 152-ФЗ «О персональных данных» от 27.07.2006 г. в
            следующих целях:
          </p>
          <ul className="ulRingRose">
            <li className="">Осуществление клиентской поддержки</li>
            <li>Получения Пользователем информации о маркетинговых событиях</li>
            <li>
              Проведения аудита и прочих внутренних исследований с целью
              повышения качества предоставляемых услуг.
            </li>
          </ul>
          <p>
            Под персональными данными подразумевается любая информация личного
            характера, позволяющая установить личность Пользователя/Покупателя
            такая как:
          </p>
          <ul className="ulRingRose">
            <li>Фамилия, Имя, Отчество</li>
            <li>Дата рождения</li>
            <li>Контактный телефон</li>
            <li>Адрес электронной почты</li>
            <li>Почтовый адрес</li>
          </ul>
          <p>
            Персональные данные Пользователей хранятся исключительно на
            электронных носителях и обрабатываются с использованием
            автоматизированных систем, за исключением случаев, когда
            неавтоматизированная обработка персональных данных необходима в
            связи с исполнением требований законодательства. Компания обязуется
            не передавать полученные персональные данные третьим лицам, за
            исключением следующих случаев:
          </p>
          <ul className="ulRingRose">
            <li>
              По запросам уполномоченных органов государственной власти РФ
              только по основаниям и в порядке, установленным законодательством
              РФ
            </li>
            <li>
              Стратегическим партнерам, которые работают с Компанией для
              предоставления продуктов и услуг, или тем из них, которые помогают
              Компании реализовывать продукты и услуги потребителям. Мы
              предоставляем третьим лицам минимальный объем персональных данных,
              необходимый только для оказания требуемой услуги или проведения
              необходимой транзакции.
            </li>
          </ul>
          <p>
            Компания оставляет за собой право вносить изменения в одностороннем
            порядке в настоящие правила, при условии, что изменения не
            противоречат действующему законодательству РФ. Изменения условий
            настоящих правил вступают в силу после их публикации на Сайте.
          </p>
        </div>
      </Wrapper>
    </>
  );
};
