import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { DGSButton } from "../components/DGSButton";

export const MainForm = () => {
  const mainForm = useRef();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };
  useEffect(() => {
    setTimeout(() => {
      mainForm.current.classList.remove("opacity-0");
    });

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
      ref={mainForm}
      className="relative flex w-full sm:w-2/3 flex-col items-start gap-4 pt-[15%] sm:pt-[7%] opacity-0 transition-opacity duration-[1500ms] select-none"
      style={{
        transform: `translateY(${offsetY / 3}px)`,
      }}
    >
      <h1 className="uppercase text-zinc-50">
        профессиональная поддержка
        <br />
        для вашего бизнеса
      </h1>
      <p className="pb-4 text-zinc-100">
        Мы предоставляем комплексные IT-услуги, которые обеспечивают стабильную
        и безопасную работу ваших сервисов, позволяя вам сосредоточиться на
        росте и развитии.
      </p>
      <Link to="/services">
        <DGSButton filled={true}>Подробнее</DGSButton>
      </Link>
    </div>
  );
};
