import React from "react";
import { ServiceCard } from "../components/ServiceCard";
import { PageHeader } from "../components/PageHeader";
import { Wrapper } from "../components/Wrapper";
import { FormContactUs } from "../components/Forms/FormContactUs";

export const Services = () => {
  window.scroll(0, 0);
  return (
    <div>
      <PageHeader pageName="Предоставляемые IT Услуги" />
      {/* Набор карточек */}
      <Wrapper>
        <div className="py-14 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10">
          <ServiceCard
            serviseName="Обслуживание IT инфраструктуры"
            serviseHref="/services/hardware-maintenance"
          >
            Комплексная поддержка офисного оборудования, персональных
            компьютеров, серверного и телекоммуникационного оборудования
            независимо от размера предприятия и количества задействованной
            техники.
          </ServiceCard>
          <ServiceCard
            serviseName="Поддержка пользователей"
            serviseHref="/services/user-support"
          >
            Эффективная техническая поддержка для решения любых IT-проблем.
            Включает создание и поддержку базы знаний для быстрого поиска
            решений, а также удаленное администрирование через 1С-Коннект для
            оперативного обслуживания систем.
          </ServiceCard>
          <ServiceCard
            serviseName="База знаний"
            serviseHref="/services/knowledge-base"
          >
            Создание и поддержка актуальной базы знаний для вашей компании,
            включающей инструкции, решения часто возникающих проблем и
            рекомендации по оптимизации IT-систем. Это позволяет быстрее решать
            задачи, обучать сотрудников и повышать эффективность работы вашей
            IT-инфраструктуры.
          </ServiceCard>
          <ServiceCard
            serviseName="Система мониторинга"
            serviseHref="/services/monitoring"
          >
            Круглосуточный мониторинг всех элементов вашей IT-системы.
            Обеспечивает раннее выявление проблем, оптимизацию
            производительности, автоматические уведомления, что помогает
            поддерживать бесперебойную работу инфраструктуры и минимизировать
            риски для бизнеса.
          </ServiceCard>
          <ServiceCard
            serviseName="IT консалтинг и аудит"
            serviseHref="/services/consulting-and-audit"
          >
            Профессиональный анализ и оптимизация вашей IT-инфраструктуры. Мы
            предоставляем консультации по выбору и внедрению IT-решений,
            автоматизации процессов и повышению безопасности. Также проводим
            комплексный аудит для выявления рисков и улучшения
            производительности ваших систем.
          </ServiceCard>
          <ServiceCard
            serviseName="IT аутсорсинг"
            serviseHref="/services/outsourcing"
          >
            Комплексное обслуживание вашей IT-инфраструктуры, включая поддержку,
            администрирование, безопасность и облачные решения. Мы берём на себя
            все IT-задачи, снижая затраты и повышая эффективность, чтобы вы
            могли сосредоточиться на развитии бизнеса.
          </ServiceCard>
          <ServiceCard
            serviseName="Построение ИТ инфраструктуры с нуля"
            serviseHref="/services/new-services"
          >
            Проектирование и внедрение ИТ-инфраструктуры, полностью
            соответствующей потребностям вашего бизнеса. Мы поможем вам создать
            масштабируемую, безопасную и высокоэффективную систему, обеспечив
            надежную работу всех элементов от серверов до приложений.
          </ServiceCard>
          <ServiceCard
            serviseName="Перенос инфраструктуры и сервисов в облако"
            serviseHref="/services/cloud-migration"
          >
            Перенос вашей IT-инфраструктуры и сервисов в облако для повышения
            гибкости, снижения затрат и улучшения доступности. Мы обеспечиваем
            безопасный и бесперебойный переход, оптимизацию облачной среды и
            поддержку на всех этапах миграции.
          </ServiceCard>
          <ServiceCard
            serviseName="Модернизация имеющихся сервисов и инфраструктуры"
            serviseHref="/services/services-update"
          >
            Обновление и оптимизация вашей IT-инфраструктуры для повышения её
            производительности, безопасности и соответствия современным
            требованиям бизнеса. Мы предлагаем комплексное решение по
            модернизации оборудования, программного обеспечения и процессов для
            улучшения эффективности работы и снижения затрат.
          </ServiceCard>
          <ServiceCard
            serviseName="Резервное копирование и архивация данных"
            serviseHref="/services/data-backup"
          >
            Надёжное решение для защиты данных от потерь и сбоев. Мы
            обеспечиваем регулярное резервное копирование, многослойную защиту и
            долгосрочную архивацию информации. Это гарантирует безопасность
            ваших данных и их восстановление в случае непредвиденных ситуаций.
          </ServiceCard>
        </div>
        <FormContactUs />
      </Wrapper>
    </div>
  );
};
