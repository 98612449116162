import React from "react";

const borderStyles =
  "border rounded-full border-rose-500 hover:border-black hover:text-black hover:no-underline";
const filledStyles =
  "transition duration-300 hover:bg-gradient-to-r hover:from-rose-500 hover:to-rose-600 rounded-full text-white bg-rose-500 shadow-md hover:no-underline";
const disabledStyles =
  "bg-neutral-300 border border-neutral-400 rounded-full text-neutral-700 hover:bg-neutral-300 hover:border-neutral-400 hover:no-underline";
export const DGSButton = ({
  children,
  border = false,
  filled = false,
  disabled = false,
}) => {
  return (
    <div
      className={`flex w-full justify-center px-5 py-2 text-rose-500 hover:underline underline-offset-2 cursor-pointer ${
        border && borderStyles
      } ${filled && filledStyles} ${disabled && disabledStyles}`}
    >
      {children}
    </div>
  );
};
