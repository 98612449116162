import React from "react";
import servicesupdate from "./servicesupdate.webp";
import { FormRequest } from "../../../components/Forms/FormRequest";
export const ServicesUpdateContent = () => {
  return (
    <div>
      <section className="grid lg:grid-cols-2 gap-10 py-10 lg:pt-20">
        <img
          className="object-cover min-h-[250px] max-h-[300px] h-full w-full rounded-lg overflow-hidden"
          src={servicesupdate}
          alt="Обновление инфраструктуры и сервисов"
        ></img>

        <div>
          <h1 className="text-3xl font-bold text-black pb-6">
            Поможем обновить вашу инфраструктуру и сервисы
          </h1>

          <p className="text-black">
            Модернизация ИТ-инфраструктуры — это процесс обновления и
            оптимизации существующих IT-систем с целью повышения их
            эффективности, безопасности и адаптации к современным требованиям
            бизнеса. Мы предлагаем услуги по модернизации ИТ-инфраструктуры для
            компаний, которые хотят улучшить производительность, сократить
            расходы и подготовить свою инфраструктуру к будущим вызовам.
          </p>
        </div>
      </section>
      <FormRequest />

      <h2>Этапы модернизации ИТ-инфраструктуры:</h2>
      <ol>
        <li>
          <h3>Аудит и анализ текущей инфраструктуры:</h3> Мы начинаем с
          глубокого анализа всех компонентов вашей ИТ-инфраструктуры, включая
          серверы, сети, системы хранения данных и программное обеспечение. Это
          позволяет выявить узкие места, старые технологии и компоненты,
          требующие обновления или замены.
        </li>
        <li>
          <h3>Разработка плана модернизации:</h3> На основе проведённого аудита
          мы разрабатываем план модернизации, учитывая потребности бизнеса,
          бюджетные ограничения и цели компании. План включает рекомендации по
          обновлению оборудования, внедрению новых технологий и улучшению
          безопасности.
        </li>
        <li>
          <h3>Обновление оборудования и программного обеспечения:</h3> Мы
          заменяем устаревшие серверы, устройства хранения данных, сетевые
          компоненты, а также обновляем или внедряем новое программное
          обеспечение. Это помогает повысить производительность, улучшить
          совместимость и увеличить надежность системы.
        </li>
        <li>
          <h3>Оптимизация и автоматизация процессов:</h3> Мы внедряем передовые
          инструменты для автоматизации и оптимизации процессов управления
          инфраструктурой. Это позволяет значительно снизить количество ошибок,
          ускорить рабочие процессы и повысить общую эффективность.
        </li>
        <li>
          <h3>Обеспечение безопасности и защиты данных:</h3> Мы внедряем
          современные решения для защиты данных, включая системы резервного
          копирования, антивирусную защиту, мониторинг и предотвращение угроз.
          Модернизация ИТ-инфраструктуры всегда включает в себя усиление мер
          безопасности.
        </li>
        <li>
          <h3>Тестирование и запуск:</h3> После выполнения всех работ мы
          проводим всестороннее тестирование обновлённой инфраструктуры.
          Убедившись в её стабильности и производительности, мы обеспечиваем
          плавный переход на новую систему без сбоев и потерь данных.
        </li>
        <li>
          <h3>Поддержка и мониторинг:</h3> Мы предоставляем услуги по
          мониторингу и технической поддержке модернизированной инфраструктуры,
          чтобы гарантировать её бесперебойную работу, безопасность и
          оптимальную производительность в будущем.
        </li>
      </ol>

      <h2 className="pt-10 pb-5">
        Преимущества модернизации ИТ-инфраструктуры:
      </h2>
      <ul className="ulRingRose">
        <div className="flex flex-col gap-5">
          <li>
            <strong>Повышение производительности:</strong> Модернизация
            позволяет ускорить работу систем, улучшить отклик и повысить общую
            эффективность инфраструктуры.
          </li>
          <li>
            <strong>Снижение затрат:</strong> Обновление устаревшего
            оборудования и внедрение новых технологий помогает значительно
            сократить эксплуатационные расходы и энергозатраты.
          </li>
          <li>
            <strong>Усиление безопасности:</strong> Модернизированные системы
            обеспечивают более высокий уровень защиты данных и предотвращения
            угроз.
          </li>
          <li>
            <strong>Масштабируемость:</strong> Новые решения позволяют вам легко
            масштабировать инфраструктуру по мере роста бизнеса.
          </li>
          <li>
            <strong>Адаптация к современным технологиям:</strong> Мы внедряем
            инновационные решения, которые соответствуют текущим и будущим
            требованиям вашего бизнеса.
          </li>
        </div>
      </ul>

      <h3 className="pt-10">
        Модернизация ИТ-инфраструктуры с нашей компанией — это надежный способ
        подготовить вашу систему к будущему, повысить её производительность и
        безопасность, а также улучшить эффективность всех рабочих процессов.
      </h3>
    </div>
  );
};
