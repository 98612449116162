import React from "react";
import { Wrapper } from "../components/Wrapper";
// import { ContextLink } from "../components/ContextLink";
import { MainImage } from "../components/MainImage";
import { MainForm } from "../components/MainForm";
import { PopularServices } from "../components/PopularServices";
import { FormRequest } from "../components/Forms/FormRequest";
import { AboutUsContent } from "./aboutUs/AboutUsContent";
import { FormContactUs } from "../components/Forms/FormContactUs";

export const Main = () => {
  window.scroll(0, 0);

  return (
    <>
      <div className="w-full h-[calc(100vh-50px)] min-h-[650px] overflow-hidden relative">
        <MainImage />
        <Wrapper>
          <MainForm />
        </Wrapper>
      </div>
      <PopularServices />
      <FormRequest full={false} my={true} />
      <Wrapper>
        <AboutUsContent />
      </Wrapper>
      <FormContactUs full={false} />
    </>
  );
};
