import React from "react";
import knowledge_base from "./knowledge-base.png";
import { FormRequest } from "../../../components/Forms/FormRequest";

export const KnowledgeBaseContent = () => {
  return (
    <div>
      <section className="grid lg:grid-cols-2 gap-10 py-10 lg:pt-20">
        <img
          className="object-cover min-h-[100px] max-h-[350px] lg:max-h-[500px] lg:h-full w-full rounded-lg overflow-hidden"
          src={knowledge_base}
          alt="Поддержка IT инфраструктуры"
        ></img>

        <div>
          <h1 className="text-3xl font-bold text-black pb-6">
            Создание и ведение корпоративной базы знаний
          </h1>

          <p className="text-black">
            Корпоративная база знаний — это важный инструмент для эффективного
            управления информацией и решения IT-задач в вашей компании.
            <br />
            Мы создаем и поддерживаем актуальную базу знаний, которая содержит
            подробные инструкции, решения часто возникающих проблем,
            рекомендации по оптимизации работы с IT-системами и лучшие практики.
          </p>
        </div>
      </section>
      <FormRequest />

      <h2>База знаний позволяет:</h2>
      <ul className="ulRingRose py-10">
        <div className="flex flex-col gap-5">
          <li>Сократить время на решение стандартных запросов и проблем;</li>
          <li>Упростить процесс обучения сотрудников;</li>
          <li>
            Обеспечить доступ к важной информации в любое время и с любого
            устройства;
          </li>
          <li>Повысить общую эффективность работы вашей IT-инфраструктуры.</li>
        </div>
      </ul>

      <p>
        <strong>
          Мы регулярно обновляем и расширяем базу, добавляя новые статьи и
          инструкции, чтобы обеспечить вам всегда актуальную информацию для
          самостоятельного решения возникающих задач.
        </strong>
      </p>
    </div>
  );
};
