import React from "react";
import { Link } from "react-router-dom";

export const ServiceCard = ({
  serviseName = "Услуга",
  serviseHref = "/404",
  children,
}) => {
  return (
    <Link to={serviseHref}>
      <div className="border-t-4 border-t-neutral-600 border px-4 py-6 hover:shadow-2xl hover:bg-rose-100 hover:border-rose-100 hover:border-t-4 hover:border-t-rose-500 overflow-hidden h-full">
        <h1 className=" text-lg font-semibold">{serviseName}</h1>
        <div className="py-2"></div>
        <p className="text-sm text-neutral-600">{children}</p>
      </div>
    </Link>
  );
};
