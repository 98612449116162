import React from "react";
import monitoring from "./monitoring.jpg";
import { FormRequest } from "../../../components/Forms/FormRequest";

export const MonitoringContent = () => {
  return (
    <div>
      <section className="grid lg:grid-cols-2 gap-10 py-10 lg:pt-20">
        <img
          className="object-cover min-h-[100px] max-h-[350px] lg:max-h-[500px] lg:h-full w-full rounded-lg overflow-hidden"
          src={monitoring}
          alt="Мониторинг"
        ></img>

        <div>
          <h1 className="text-3xl font-bold text-black pb-6">
            Мониторинг IT-инфраструктуры — это один из самых важных элементов
            для обеспечения стабильной и бесперебойной работы всех технологий и
            сервисов вашей компании.
          </h1>

          <p className="text-black">
            В условиях постоянных изменений и роста бизнеса критически важно
            иметь прозрачность и контроль за состоянием всех элементов
            IT-ландшафта, включая серверы, сети, приложения и базы данных.
            <br />
            Эффективная система мониторинга позволяет оперативно выявлять
            потенциальные проблемы, предотвращать их развитие и минимизировать
            риски, связанные с простоями, сбоями или утратой данных.
          </p>
        </div>
      </section>

      <FormRequest />
      <strong>
        <p>
          Наши решения по мониторингу IT-инфраструктуры обеспечивают постоянный
          анализ состояния ресурсов, с возможностью настройки оповещений и
          уведомлений о возникновении любых аномалий или неисправностей. Это
          позволяет своевременно принимать меры и устранять проблемы до того,
          как они окажут влияние на бизнес-процессы. Кроме того, системы
          мониторинга предоставляют точные данные о производительности и
          нагрузке на оборудование, что помогает выявлять узкие места и
          оптимизировать ресурсы.
        </p>

        <p>
          Современные системы мониторинга могут включать как базовые функции
          наблюдения за состоянием оборудования, так и более сложные решения,
          такие как мониторинг безопасности, управление доступом и анализ логов.
          <br />
          Мы используем только проверенные и надежные инструменты, чтобы
          гарантировать нашим клиентам высокое качество и оперативность
          обслуживания.
        </p>
      </strong>

      <h2 className="pt-10">Система мониторинга Zabbix</h2>

      <p>
        В нашей компании мы используем систему мониторинга{" "}
        <strong>Zabbix</strong>, которая является одним из самых популярных и
        мощных решений для мониторинга IT-инфраструктуры. Zabbix позволяет
        интегрировать все элементы вашей системы в единый центр управления,
        обеспечивая постоянный контроль за состоянием серверов, сетевых
        устройств, приложений и других ключевых компонентов IT-ландшафта.
      </p>

      <h3>Преимущества использования системы мониторинга:</h3>
      <ul className="ulRingRose">
        <li>
          <strong>Раннее обнаружение проблем</strong>: Система позволяет быстро
          выявить аномалии в работе серверов, приложений или сетевых устройств,
          что дает возможность предотвратить серьезные сбои и простои.
        </li>
        <li>
          <strong>Повышение производительности</strong>: Мониторинг помогает
          выявить узкие места и неэффективно использующие ресурсы элементы
          системы, что позволяет оптимизировать их работу и повысить общую
          производительность.
        </li>
        <li>
          <strong>Автоматизация уведомлений</strong>: Система автоматически
          отправляет уведомления и предупреждения о возникновении проблем, что
          позволяет оперативно реагировать и устранять неполадки без задержек.
        </li>
        <li>
          <strong>Масштабируемость</strong>: Зависимо от роста вашей
          инфраструктуры, мониторинг может быть легко масштабирован, поддерживая
          как небольшие, так и крупные, распределенные системы.
        </li>
      </ul>

      <p className="pt-3">
        <strong>
          C использованием Zabbix мы предоставляем вам мощный и гибкий
          инструмент для обеспечения бесперебойной работы IT-систем, позволяя
          оперативно реагировать на возникающие проблемы и минимизировать риски
          для вашего бизнеса.
        </strong>
      </p>
    </div>
  );
};
