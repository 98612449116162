import React from "react";
import { Link } from "react-router-dom";

export const ContextLink = ({ to, offset = "", children }) => {
  let styles = "";
  switch (offset) {
    case "1":
      styles =
        "decoration-rose-500 underline underline-offset-[8px] hover:underline-offset-[6px] decoration-[4px] hover:decoration-[6px] hover:bg-rose-500 hover:text-white";
      break;
    default:
      styles =
        "decoration-rose-500 underline underline-offset-[5px] hover:underline-offset-[3px] decoration-[0.5px] hover:decoration-[4px] hover:bg-rose-500 hover:text-white";
  }
  return (
    <Link to={to} className={styles}>
      {children}
    </Link>
  );
};
